import '../App.css';
import Sidebar from '../core/presentation/layout/Sidebar';
import Box from '@mui/joy/Box';
import { Outlet } from 'react-router-dom';
import Header from '../core/presentation/layout/Header';
import DynamicBreadcrumbs from '../core/presentation/DynamicBreadcrumbs';
import { useAppManagementContext } from './application/AppContext';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

function App() {
  const { seeSidebar, toggleSidebar } = useAppManagementContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Sidebar />
        <Box
          component="main"
          className="MainContent"
          onClick={() => {
            if (isMobile && seeSidebar) {
              toggleSidebar();
            }
          }}
          sx={{
            pt: { xs: 'calc(12px + var(--Header-height))', md: 3 },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
            overflow: 'auto',
            opacity: {
              xs: `${seeSidebar ? '0.5' : '1'}`,
              md: 1,
            },
          }}>
          <Box sx={{ px: { xs: 2, md: 6 } }}>
            <Header />
            <Box
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex',
                },
              }}>
              <DynamicBreadcrumbs />
            </Box>

            <Outlet />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default App;
