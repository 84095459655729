import React, { useEffect, useState } from 'react';
import Input from '@mui/joy/Input';
import Select from '@mui/joy/Select';
import { Option } from '@mui/joy';
import dayjs from 'dayjs';
import Grid from '@mui/joy/Grid';
import { t } from 'i18next';

import { useGazetteListManagementContext } from '../../application/GazetteListPageContext';
import { GazettesCreate } from './GazettesCreate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export function GazettesListSearchInput() {
  const { setSearchQuery, setSelectedSearchField, selectedSearchField } = useGazetteListManagementContext();
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [maxDate, setMaxDate] = useState<Date>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date);
      setSearchQuery(dayjs(date).format('YYYY-MM-DD'));
    }
  };

  useEffect(() => {
    setMaxDate(() => new Date());
  }, []);

  function handleSearch(value: string) {
    setSearchQuery(value);
  }

  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
      <Grid xs={4}>
        <Select
          value={selectedSearchField}
          onChange={(_event, value) => {
            switch (value) {
              case 'gazetteNumber':
                setSearchQuery('');
                break;
              case 'publicationDate':
                setSearchQuery(dayjs(maxDate).format('YYYY-MM-DD'));
                break;
              case 'type':
                setSearchQuery('ordinary');
                break;
              case 'jurisdiction':
                setSearchQuery('Venezuela');
                break;
              default:
                break;
            }
            setSelectedSearchField(value || 'gazetteNumber');
          }}>
          <Option value="gazetteNumber">{t('gazettes.number')}</Option>
          <Option value="publicationDate">{t('gazettes.date')}</Option>
          <Option value="type">{t('gazettes.type')}</Option>
          <Option value="jurisdiction">{t('gazettes.jurisdiction')}</Option>
        </Select>
      </Grid>
      <Grid xs={4}>
        {selectedSearchField === 'gazetteNumber' && (
          <Grid xs={4}>
            <Input
              type="number"
              onChange={e => handleSearch(e.target.value)}
              slotProps={{
                input: {
                  ref: inputRef,
                  min: 1,
                  max: 900000,
                  step: 1,
                },
              }}
            />
          </Grid>
        )}

        {selectedSearchField === 'publicationDate' && (
          <Grid xs={4}>
            <DatePicker
              selected={selectedDate}
              maxDate={maxDate}
              dateFormat="dd-MM-yyyy"
              showYearDropdown
              showMonthDropdown
              onChange={date => {
                handleDateChange(date);
              }}
              customInput={<Input />}
            />
          </Grid>
        )}

        {selectedSearchField === 'type' && (
          <Grid xs={4}>
            <Select defaultValue="ordinary" onChange={(_event, value) => handleSearch(value || 'ordinary')}>
              <Option value="ordinary">{t('gazettes.ordinary')}</Option>
              <Option value="extraordinary">{t('gazettes.extraordinary')}</Option>
            </Select>
          </Grid>
        )}

        {selectedSearchField === 'jurisdiction' && (
          <Grid xs={4}>
            <Select defaultValue="Venezuela" onChange={(_event, value) => handleSearch(value || 'Venezuela')} disabled>
              <Option value="Venezuela">{t('gazettes.venezuela')}</Option>
            </Select>
          </Grid>
        )}
      </Grid>
      <Grid xs={4}>
        <GazettesCreate />
      </Grid>
    </Grid>
  );
}
