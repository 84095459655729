import { createContext, useContext } from 'react';
import { AppType, useAppManagement } from '../hooks/useAppManagement';

const AppContext = createContext<AppType | null>(null);

export const AppManagementProvider = ({ children }: { children: any }) => {
  const appManagement = useAppManagement();

  return <AppContext.Provider value={appManagement}>{children}</AppContext.Provider>;
};

export const useAppManagementContext = () => {
  const context = useContext(AppContext);

  if (context === null) {
    throw new Error('useAppManagementContext must be used within a AppManagementProvider');
  }
  return context;
};
