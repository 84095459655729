import GlobalStyles from '@mui/joy/GlobalStyles';
import IconButton from '@mui/joy/IconButton';
import Sheet from '@mui/joy/Sheet';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import DynamicBreadcrumbs from '../DynamicBreadcrumbs';
import { useAppManagementContext } from '../../../app/application/AppContext';

export default function Header() {
  const { seeSidebar, toggleSidebar } = useAppManagementContext();

  return (
    <Sheet
      sx={{
        display: { xs: 'flex', md: `${seeSidebar ? 'none' : 'flex'}` },
        alignItems: 'center',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100vw',
        height: 'var(--Header-height)',
        zIndex: 1190,
        p: 2,
        gap: 1,
        borderBottom: '1px solid',
        borderColor: 'background.level1',
        boxShadow: 'sm',
      }}>
      <GlobalStyles
        styles={theme => ({
          ':root': {
            '--Header-height': '52px',
            [theme.breakpoints.up('md')]: {
              '--Header-height': '52px',
            },
          },
        })}
      />
      <IconButton
        onClick={toggleSidebar}
        variant="outlined"
        color="neutral"
        size="sm"
        sx={{
          display: 'flex',
        }}>
        <MenuRoundedIcon />
      </IconButton>
      <DynamicBreadcrumbs />
    </Sheet>
  );
}
