import { useState } from 'react';

export type AppType = {
  seeSidebar: boolean;
  toggleSidebar: () => void;
};

export const useAppManagement = (): AppType => {
  const [seeSidebar, setSeeSidebar] = useState(true);

  const toggleSidebar = () => {
    setSeeSidebar(previousValue => !previousValue);
  };

  return {
    seeSidebar,
    toggleSidebar,
  };
};
