import React from 'react';
import { Skeleton, Typography, Table } from '@mui/joy';

interface GazetteTableSkeletonProps {
  rows: number;
  columns: number;
}

export const GazetteTableSkeleton: React.FC<GazetteTableSkeletonProps> = ({ rows, columns }) => {
  return (
    <>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <tr key={`skeleton-row-${rowIndex}`}>
          {Array.from({ length: columns }).map((_, colIndex) => (
            <td key={`skeleton-cell-${rowIndex}-${colIndex}`}>
              <Typography>
                <Skeleton>{'Skeleton Input'}</Skeleton>
              </Typography>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};
