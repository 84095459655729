import { useCallback, useEffect, useState } from 'react';
import http from '../../../../core/infrastructure/HttpService';
import { Gazette } from '../../domain/gazette.model';
import { useParams } from 'react-router-dom';

export interface GazetteListManagementInterface {
  gazettes: Gazette[];
  loading: boolean;
  error: string | null;
  fetchGazettes: () => void;
  setError: (error: string | null) => void;
  setSearchQuery: (value: string) => void;
  searchQuery: string;
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
  page: number;
  pageSize: number;
  selectedSearchField: string;
  setSelectedSearchField: (value: string) => void;
  sortOrder: string;
  orderBy: string;
  setOrderBy: (value: string) => void;
  setSortOrder: (value: string) => void;
}

const useGazetteListManagement = (): GazetteListManagementInterface => {
  const [gazettes, setGazettes] = useState<Gazette[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [selectedSearchField, setSelectedSearchField] = useState<string>('gazetteNumber');
  const { sortOrder: initialSortOrder, orderBy: initialOrderBy } = useParams();
  const [sortOrder, setSortOrder] = useState(initialSortOrder || 'asc');
  const [orderBy, setOrderBy] = useState(initialOrderBy || 'publicationDate');

  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const fetchGazettes = useCallback(async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams({
        page: page.toString(),
        pageSize: pageSize.toString(),
        sortOrder: sortOrder,
        orderBy: orderBy,
      });

      if (debouncedSearchQuery) {
        params.append(selectedSearchField, debouncedSearchQuery.trim());
      }

      const response = await http.get('/gazettes', {
        params,
      });

      const newGazettes: Gazette[] = [];

      for (const gazetteJson of response.data) {
        newGazettes.push(new Gazette(gazetteJson));
      }

      setGazettes(newGazettes);
    } catch (err: any) {
      setError(err?.message);
    } finally {
      setLoading(false);
    }
  }, [debouncedSearchQuery, page, pageSize, sortOrder, orderBy]);

  useEffect(() => {
    fetchGazettes();
  }, [fetchGazettes]);

  return {
    gazettes,
    loading,
    error,
    fetchGazettes,
    setError,
    setSearchQuery,
    searchQuery,
    setPage,
    setPageSize,
    selectedSearchField,
    setSelectedSearchField,
    page,
    pageSize,
    sortOrder,
    orderBy,
    setOrderBy,
    setSortOrder,
  };
};

export { useGazetteListManagement };
