import React, { ReactElement, useState } from 'react';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import { Modal, ModalClose, Option, Select, Sheet } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Table from '@mui/joy/Table';
import Skeleton from '@mui/joy/Skeleton';
import Typography from '@mui/joy/Typography';
import PictureAsPdfSharpIcon from '@mui/icons-material/PictureAsPdfSharp';
import { useTranslation } from 'react-i18next';
import { useGazetteListManagementContext } from '../../application/GazetteListPageContext';
import { ShowPDFGazette } from '../../../../core/presentation/components/ShowPDFGazette';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { GazetteTableSkeleton } from './GazetteTableSkeleton';

dayjs.extend(utc);

interface HeadCell {
  disablePadding: boolean;
  id: keyof Gazette;
  label: string;
  numeric: boolean;
}

interface Gazette {
  uuid: string;
  gazetteNumber: number;
  type: string;
  jurisdiction?: string;
  publicationDate: Date;
  fileUrl: string;
}

export function GazettesTable() {
  const { gazettes, loading, pageSize, page, setPage, setPageSize, sortOrder, orderBy, setSortOrder, setOrderBy } =
    useGazetteListManagementContext();
  const [openModal, setOpenModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const { t } = useTranslation();

  const openPdfModal = (pdfUrl: string) => {
    setPdfUrl(pdfUrl);
    setOpenModal(true);
  };

  function handlePageChange(newPage: number) {
    setPage(newPage);
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'gazetteNumber',
      numeric: false,
      disablePadding: true,
      label: 'Número',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Tipo',
    },
    {
      id: 'jurisdiction',
      numeric: false,
      disablePadding: false,
      label: 'Jurisdicción',
    },
    {
      id: 'publicationDate',
      numeric: true,
      disablePadding: false,
      label: 'Fecha de publicación',
    },
  ];

  function handleRequestSort(property: string) {
    if (property === orderBy) {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
      } else if (sortOrder === 'desc') {
        setSortOrder('asc');
      }
    } else {
      setOrderBy(property);
      setSortOrder('asc');
    }
  }

  function EnhancedTableHead() {
    return (
      <thead>
        <tr>
          {headCells.map(headCell => (
            <th key={headCell.id} onClick={() => handleRequestSort(headCell.id)} style={{ cursor: 'pointer' }}>
              {headCell.label}
              {orderBy === headCell.id && <span>{sortOrder === 'desc' ? ' ↓' : ' ↑'}</span>}
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  return (
    <>
      <Box>
        <Table hoverRow stripe="odd">
          <EnhancedTableHead />
          <tbody>
            {loading ? (
              <GazetteTableSkeleton rows={pageSize} columns={4} />
            ) : gazettes.length === 0 ? (
              <tr>
                <td colSpan={4} style={{ textAlign: 'center', padding: '16px' }}>
                  <Typography color="neutral">{t('gazettes.no_results')}</Typography>
                </td>
              </tr>
            ) : (
              gazettes.map(gazette => (
                <tr key={`gazette-row-${gazette.uuid}`}>
                  <td>
                    <Box display="flex" alignItems="center">
                      <IconButton onClick={() => openPdfModal(gazette.fileUrl)}>
                        <PictureAsPdfSharpIcon />
                      </IconButton>
                      <Typography>{gazette.gazetteNumber}</Typography>
                    </Box>
                  </td>
                  <td>{t(`gazettes.${gazette.type}`)}</td>
                  <td>{gazette.jurisdiction}</td>
                  <td>{dayjs(gazette.publicationDate).utc().format('DD-MM-YYYY')}</td>
                </tr>
              ))
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    justifyContent: 'flex-end',
                  }}>
                  <FormControl orientation="horizontal" size="sm">
                    <FormLabel>{t('gazettes.gazette_per_page')}</FormLabel>
                    <Select value={pageSize} onChange={(e, value) => setPageSize(value || 10)}>
                      <Option value={10}>10</Option>
                      <Option value={25}>25</Option>
                      <Option value={50}>50</Option>
                    </Select>
                  </FormControl>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <IconButton
                      size="sm"
                      color="neutral"
                      variant="outlined"
                      disabled={page === 1}
                      onClick={() => handlePageChange(page - 1)}
                      sx={{ bgcolor: 'background.surface' }}>
                      <KeyboardArrowLeftIcon />
                    </IconButton>

                    <Typography>
                      {t('gazettes.page')} {page}
                    </Typography>

                    <IconButton
                      size="sm"
                      color="neutral"
                      variant="outlined"
                      disabled={gazettes.length < pageSize}
                      onClick={() => handlePageChange(page + 1)}
                      sx={{ bgcolor: 'background.surface' }}>
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </Box>
                </Box>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Sheet
          sx={{
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            width: '80%',
            height: '80%',
            display: 'flex',
            flexDirection: 'column',
          }}>
          <ModalClose />
          <ShowPDFGazette pdfUrl={pdfUrl} />
        </Sheet>
      </Modal>
    </>
  );
}
