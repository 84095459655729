import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormLabel, Sheet, Option, Select, IconButton, Typography, Button } from '@mui/joy';
import { DeleteForever, Edit } from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'; //TODO Actualizar
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Refresh from '@mui/icons-material/Refresh';

import { Table, TableCell } from '../../../../components/Table';
import { useActListManagementContext } from '../../../application/hooks/useActListManagementContext';
import { deleteActModalAtom } from '../../../application/acts-atoms';
import { headCells } from '../../utils/actsTableUtils';
import { loadSkeletonRows } from './LoadSkeleton';
import { useTableControls } from '../../../application/hooks/useTableControls';

export default function ActsTable() {
  const { t } = useTranslation();
  const [deleteActModalState, setDeleteActModalState] = useRecoilState(deleteActModalAtom);
  const navigate = useNavigate();
  const { handlePageChange, handlePageSizeChange, handleRequestSort } = useTableControls();

  const {
    page,
    pageSize,
    orderBy,
    sortOrder,
    actsQuery: { data: acts, isLoading, isError, refetch },
  } = useActListManagementContext();

  const onDeleteClicked = (actUuid: string) => {
    setDeleteActModalState({ ...deleteActModalState, isDeleteActModalVisible: true, actUuid });
  };

  const onEditClicked = (actUuid: string) => {
    navigate(`/app/acts/${actUuid}/edit`);
  };

  const renderTableHead = () => (
    <thead>
      <tr>
        {headCells.map(headCell => (
          <th
            key={headCell.id}
            onClick={headCell.sortable ? () => handleRequestSort(headCell.id) : undefined}
            style={{
              cursor: headCell.sortable ? 'pointer' : undefined,
              verticalAlign: 'middle',
              width: `${headCell.id === 'actName' ? '30%' : ''}`,
            }}>
            {headCell.label}
            {orderBy === headCell.id && <span>{sortOrder === 'desc' ? ' ↓' : ' ↑'}</span>}
          </th>
        ))}
        <th
          style={{
            verticalAlign: 'middle',
            textAlign: 'center',
            width: '15%',
          }}>
          {t('acts.list.actions')}
        </th>
      </tr>
    </thead>
  );

  const renderTableData = () => {
    if (isLoading) {
      return loadSkeletonRows(pageSize);
    }

    if (isError) {
      return (
        <tr>
          <TableCell colSpan={4} style={{ height: '6rem' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}>
              <Typography> {t('acts.list.something_went_wrong')} </Typography>
              <IconButton onClick={() => refetch()} variant="outlined" size="sm" style={{ marginLeft: '1rem' }}>
                <Refresh />
              </IconButton>
            </Box>
          </TableCell>
        </tr>
      );
    }

    return acts && acts.length > 0 ? (
      acts.map(act => (
        <tr key={`act-row-${act.uuid}`}>
          <td>{dayjs(act.date).format('DD-MM-YYYY')}</td>
          <td>{act.gazette.gazetteNumber}</td>
          <td>{act.reprint ? t('acts.list.yes') : t('acts.list.no')}</td>
          <td>{act.actName}</td>
          <td>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1, // Espaciado entre los botones
              }}>
              <Button size="sm" variant="solid" startDecorator={<Edit />} onClick={() => onEditClicked(act.uuid)}>
                {'Editar'}
              </Button>
              <Button
                size="sm"
                variant="solid"
                color="danger"
                startDecorator={<DeleteForever />}
                onClick={() => onDeleteClicked(act.uuid)}>
                {'Borrar'}
              </Button>
            </Box>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={4} style={{ height: '6rem' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}>
            <Typography>{t('acts.list.no_acts')}</Typography>
          </Box>
        </td>
      </tr>
    );
  };

  const renderTableFooter = () => (
    <tfoot>
      <tr>
        <td colSpan={5}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              justifyContent: 'flex-end',
            }}>
            <FormControl orientation="horizontal" size="sm">
              <FormLabel>{t('acts.list.acts_page')}</FormLabel>
              <Select value={pageSize} onChange={(_, value) => handlePageSizeChange(Number(value))}>
                <Option value={10}>10</Option>
                <Option value={25}>25</Option>
                <Option value={50}>50</Option>
              </Select>
            </FormControl>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <IconButton
                size="sm"
                color="neutral"
                variant="outlined"
                disabled={page === 1}
                onClick={() => handlePageChange(page - 1)}
                sx={{ bgcolor: 'background.surface' }}>
                <KeyboardArrowLeftIcon />
              </IconButton>
              <Typography>{t('acts.list.page')}</Typography>
              <IconButton
                size="sm"
                color="neutral"
                variant="outlined"
                disabled={acts && acts.length < pageSize}
                onClick={() => handlePageChange(page + 1)}
                sx={{ bgcolor: 'background.surface' }}>
                <KeyboardArrowRightIcon />
              </IconButton>
            </Box>
          </Box>
        </td>
      </tr>
    </tfoot>
  );

  return (
    <Sheet
      sx={{
        width: '100%',
        overflow: 'auto',
      }}>
      <div style={{ minWidth: '1300px' }}>
        <Table borderAxis="both" sx={{ width: '100%' }}>
          {renderTableHead()}
          <tbody>{renderTableData()}</tbody>
          {renderTableFooter()}
        </Table>
      </div>
    </Sheet>
  );
}
